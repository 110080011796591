@each $size, $value in $sizes {
	.p-#{$size} {
		padding: $value + em;
	}
	.pl-#{$size} {
		padding-left: $value + em;
	}
	.pr-#{$size} {
		padding-right: $value + em;
	}
	.px-#{$size} {
		padding-left: $value + em;
		padding-right: $value + em;
	}
	.py-#{$size} {
		padding-top: $value + em;
		padding-bottom: $value + em;
	}
}
