@import '../scss/variables';

@import './node_modules/bootstrap/scss/bootstrap.scss';

@import './customs/backgrounds';
@import './customs/borders';
@import './customs/buttons';
@import './customs/paddings';
@import './customs/margins';
@import './customs/fonts';
@import './customs/dropzone';
@import './customs/text';
@import './customs/inputs';
@import './customs/tooltips';
@import './customs/tables';
@import './customs/thumbnail';

.vertical-align {
	display: flex;
	justify-content: center;
	align-items: center;
}
.vertical-align-bottom {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.vertical-align-bottom-left {
	display: flex;
	justify-content: left;
	align-items: flex-end;
}
.vertical-align-bottom-right {
	display: flex;
	justify-content: right;
	align-items: flex-end;
}
.vertical-align-left {
	display: flex;
	justify-content: left;
	align-items: center;
}
.vertical-align-right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.hr {
	width: 100%;
	height: 1px;
	background-color: $light;
}

.logo-certero {
	height: 2rem;
	@media only screen and (max-width: 900px) {
		height: 2rem;
	}
}

.display-flex {
	display: flex;
}

.space-between {
	justify-content: space-between;
}

.fullspage {
	height: 100vh;
}

.box-shadow {
	box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.1);
}

.card-shadow {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3rem;
	border-radius: $border-radius;
	box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.1);
	@media only screen and (max-width: 900px) {
		padding: 2rem;
	}
	.title {
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 1rem;
	}
}

.text-right {
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

.fullscreen {
	height: 100vh;
}
.page-fullscreen {
	height: 93vh;
}

.gradient {
	background: rgb(19, 118, 111);
	background: linear-gradient(90deg, rgba(19, 118, 111, 1) 29%, rgba(53, 225, 124, 1) 100%);
	min-height: 22rem;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	.profile {
		cursor: pointer;
		position: relative;
		padding: 2rem;
		background-color: white;
		border-radius: 100%;
		.picture {
			width: 8rem;
		}
	}
}

.display-none {
	display: none;
}

.circle-company {
	background-color: rgba($primary, 0.5);
	border-radius: 100%;
	padding: 1rem 2.06rem;
	font-size: 2rem;
}

.limit {
	text-overflow: ellipsis;
	max-width: 15rem;
	white-space: nowrap;
	overflow: hidden;
}

.title-personal {
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 1rem;
}
