/* You can add global styles to this file, and also import other style files */

html,
body {
	// background-color: rgba(0, 0, 0, 0.05);
	height: 100vh;
}

.imgContent {
	width: 100%;
	object-fit: cover;
}
