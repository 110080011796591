th {
	font-size: 17px !important;
	font-weight: 500 !important;
}
td {
	font-weight: 300 !important;
}
.table-responsive {
	padding-left: 1rem;
	padding-right: 1rem;
}
