@each $size, $value in $sizes {
	.br-#{$size} {
		border-radius: $value + px;
	}
	.br-top-#{$size} {
		border-top-left-radius: $value + px;
		border-top-right-radius: $value + px;
	}
	.br-bottom-#{$size} {
		border-bottom-left-radius: $value + px;
		border-bottom-right-radius: $value + px;
	}
}
