@each $size, $value in $sizes {
	.fs-#{$size} {
		font-size: $value + px !important;
	}
}

.fw-500 {
	font-weight: 500;
}
.fw-300 {
	font-weight: 300 !important;
}
