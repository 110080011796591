@import '../../scss/variables';

ngx-dropzone {
	width: 100% !important;
	height: 100% !important;
	border: $primary 1.5px dashed !important;
	border-radius: 1rem !important;
	ngx-dropzone-label {
		color: #000;
	}
}
