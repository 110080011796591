@import '../variables';

.thumbnail-container {
	text-align: center;
	color: white;
	position: fixed;
	padding: 1rem;
	font-size: 14px;
	max-width: 10rem;
	max-height: 10rem;
	line-height: initial;
	min-width: 5rem;
	background: white;
	border-radius: 10px;
	box-sizing: border-box;
	opacity: 0;
	transform: translate(-50%, -30%);
	animation: tooltip-slide 0.18s ease-out 0.1s;
	animation-fill-mode: forwards;
	pointer-events: none;
	border: 1px solid rgb(0 0 0 / 10%);
	border-radius: 5px;
}

@keyframes tooltip-slide {
	0% {
		opacity: 0;
		transform: translate(-50%, -30%);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, 0);
	}
}
