@each $size, $value in $sizes {
	.m-#{$size} {
		margin: $value + em;
	}
	.ml-#{$size} {
		margin-left: $value + em;
	}
	.mr-#{$size} {
		margin-right: $value + em;
	}
	.mx-#{$size} {
		margin-left: $value + em;
		margin-right: $value + em;
	}
	.my-#{$size} {
		margin-top: $value + em;
		margin-bottom: $value + em;
	}
}
