@import '../variables';

.spinner-loading-button {
	width: 17px;
	height: 17px;
}

.btn-outline-primary:hover {
	color: white !important;
}

.btn-certero {
	min-width: $width-button !important;
	@media only screen and (max-width: 900px) {
		width: 100%;
	}
}

.btn-back {
	border: 1px solid #b4b4b4 !important;
	box-shadow: 0px 1px 2px #d6d6d6 !important;
	font-size: $primary !important;
}
